import Modal from '../../UI/Modal/Modal';
import FormControlWrap from '../../Scheduler/Forms/FormControlWrap/FormControlWrap';
import Button from '../../UI/Input/Button/Button';
import classes from './ConfirmPopUp.module.css';

const ConfirmPopUp = (props) => {
  const { actionType, onClose, onSubmit } = props;

  return (
    <Modal classList={['modalSmall']} hasHeader={false} onClose={onClose}>
      <div className={classes.ConfirmPopUp}>
        대관 일정을 {actionType.split(' ')[1]}하시겠습니까?
        <br />이 작업은 되돌릴 수 없습니다.
        <FormControlWrap>
          <Button
            attribute={{ type: 'button' }}
            classList={['Button--Delete']}
            onClick={onClose}
          >
            취소
          </Button>
          <Button attribute={{ type: 'button' }} onClick={onSubmit}>
            확인
          </Button>
        </FormControlWrap>
      </div>
    </Modal>
  );
};

export default ConfirmPopUp;
